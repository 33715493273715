<script>
  export let type;
  export let color;
  export let theme;
  export { classes as class };
  let classes;
</script>

{#if type == 'welcome'}
  {#if theme == 'theme-light'}
    <svg
      class="{classes} w-full h-auto transition-all duration-250 ease-out"
      fill="none"
      height="50"
      viewBox="0 0 115 50"
      width="115"
      xmlns="http://www.w3.org/2000/svg"
    ><g class={color ? color : 'text-c-text'} fill="currentColor">
        <path
          d="M84.3 8.6c.08-.48.38-.82.95-.82.52 0 .84.32.9.83zM96 7.82c-.64 0-1.07.48-1.07 1.2s.43 1.2 1.07 1.2 1.07-.48 1.07-1.2-.43-1.2-1.07-1.2zM107.09 7.78c-.57 0-.86.34-.94.83H108c-.07-.5-.39-.83-.91-.83z"
        />
        <path
          clip-rule="evenodd"
          d="M72 3c0-1.1.9-2 2-2h38a2 2 0 012 2v11a2 2 0 01-2 2H75.94C74.47 18.75 72 19 72 19zm4.08 2.4l.88 5.6h1.72l.86-4.58h.1l.85 4.58h1.72l.88-5.6h-1.06l-.67 4.58h-.1l-.85-4.58h-1.65l-.86 4.58h-.1l-.66-4.58zm7.18 3.58c0 1.24.86 2.13 2.04 2.13 1.07 0 1.53-.56 1.8-.97l-.82-.48c-.14.28-.43.59-1 .59-.57 0-.95-.41-.99-.94h2.9v-.39c0-1.2-.78-2-1.94-2s-1.99.86-1.99 2.06zm4.55-3.58h1V11h-1zm1.66 3.62c0 1.23.92 2.1 2.07 2.1 1.11 0 1.8-.62 2-1.53l-1-.25c-.1.52-.38.88-.99.88-.64 0-1.07-.48-1.07-1.2s.47-1.2 1.07-1.2.92.4 1 .88l.99-.26c-.21-.9-.9-1.52-2-1.52-1.15 0-2.07.86-2.07 2.1zm6.53 2.1c-1.17 0-2.08-.86-2.08-2.1s.91-2.1 2.08-2.1 2.08.85 2.08 2.1-.91 2.1-2.08 2.1zm2.74-.12h1V8.58c0-.5.3-.73.73-.73.38 0 .64.2.64.57V11h1.01V8.58c0-.5.3-.73.72-.73.38 0 .65.2.65.57V11h1V8.34c0-.82-.58-1.37-1.3-1.37-.64 0-.96.3-1.13.67h-.12a1.1 1.1 0 00-1.05-.67c-.55 0-.88.27-1.05.65h-.11v-.59h-1zm8.4.11c-1.18 0-2.03-.9-2.03-2.13 0-1.2.82-2.06 1.99-2.06 1.15 0 1.93.8 1.93 2v.4h-2.9c.05.52.42.93 1 .93.56 0 .86-.31 1-.6l.82.49c-.28.41-.74.97-1.8.97z"
          fill-rule="evenodd"
        />
        <path
          d="M54.06 35a2.19 2.19 0 100-4.38 2.19 2.19 0 000 4.38zM61.94 35a2.19 2.19 0 100-4.38 2.19 2.19 0 000 4.38z"
        />
        <path
          clip-rule="evenodd"
          d="M50 21a6 6 0 00-6 6v8.63c-1.31.72-2.2.96-2.78.96a1.4 1.4 0 01-1.19-.59A7.2 7.2 0 0139 33.68c-.14-.46-.28-.96-.42-1.5.57-.55.84-1.39.62-2.2l-.26-.97a2.25 2.25 0 10-4.35 1.16l.26.97c.24.88.97 1.5 1.82 1.64.14.52.27 1.02.41 1.48.36 1.15.77 2.2 1.4 2.99a3.38 3.38 0 002.75 1.34c.83 0 1.76-.25 2.78-.72V43a6 6 0 006 6h16a6 6 0 006-6V27a6 6 0 00-6-6zm-3.5 7.88h23v7.87h-23z"
          fill-rule="evenodd"
        />
      </g></svg>
  {:else}
    <svg
      class="{classes} w-full h-auto transition-all duration-250 ease-out"
      fill="none"
      height="50"
      viewBox="0 0 115 50"
      width="115"
      xmlns="http://www.w3.org/2000/svg"
    ><g class={color ? color : 'text-c-text'} fill="currentColor">
        <path
          d="M84.3 8.6c.08-.48.38-.82.95-.82.52 0 .84.32.9.83zM96 7.82c-.64 0-1.07.48-1.07 1.2s.43 1.2 1.07 1.2 1.07-.48 1.07-1.2-.43-1.2-1.07-1.2zM106.15 8.6c.08-.48.37-.82.94-.82.52 0 .84.32.91.83z"
        />
        <g clip-rule="evenodd" fill-rule="evenodd">
          <path
            d="M72 3c0-1.1.9-2 2-2h38a2 2 0 012 2v11a2 2 0 01-2 2H75.94C74.47 18.75 72 19 72 19zm4.08 2.4l.88 5.6h1.72l.86-4.58h.1l.85 4.58h1.72l.88-5.6h-1.06l-.67 4.58h-.1l-.85-4.58h-1.65l-.86 4.58h-.1l-.66-4.58zm7.18 3.58c0 1.24.86 2.13 2.04 2.13 1.07 0 1.53-.56 1.8-.97l-.82-.48c-.14.28-.43.59-1 .59-.57 0-.95-.41-.99-.94h2.9v-.39c0-1.2-.78-2-1.94-2s-1.99.86-1.99 2.06zm4.55-3.58h1V11h-1zm1.66 3.62c0 1.23.92 2.1 2.07 2.1 1.11 0 1.8-.62 2-1.53l-1-.25c-.1.52-.38.88-.99.88-.64 0-1.07-.48-1.07-1.2s.47-1.2 1.07-1.2.92.4 1 .88l.99-.26c-.21-.9-.9-1.52-2-1.52-1.15 0-2.07.86-2.07 2.1zm6.53 2.1c-1.17 0-2.08-.86-2.08-2.1s.91-2.1 2.08-2.1 2.08.85 2.08 2.1-.91 2.1-2.08 2.1zm2.74-.12h1V8.58c0-.5.3-.73.73-.73.38 0 .64.2.64.57V11h1.01V8.58c0-.5.3-.73.72-.73.38 0 .65.2.65.57V11h1V8.34c0-.82-.58-1.37-1.3-1.37-.64 0-.96.3-1.13.67h-.12a1.1 1.1 0 00-1.05-.67c-.55 0-.88.27-1.05.65h-.11v-.59h-1zm8.4.11c-1.18 0-2.03-.9-2.03-2.13 0-1.2.82-2.06 1.99-2.06 1.15 0 1.93.8 1.93 2v.4h-2.9c.05.52.42.93 1 .93.56 0 .86-.31 1-.6l.82.49c-.28.41-.74.97-1.8.97zM68.5 28.88h-21v7.87h21zM56.25 32.8a2.19 2.19 0 11-4.38 0 2.19 2.19 0 014.38 0zm7.88 0a2.19 2.19 0 11-4.38 0 2.19 2.19 0 014.38 0z"
          />
          <path
            d="M44 27a6 6 0 016-6h16a6 6 0 016 6v16a6 6 0 01-6 6H50a6 6 0 01-6-6v-5.13a6.83 6.83 0 01-2.78.72c-1.18 0-2.08-.5-2.75-1.34a9.04 9.04 0 01-1.4-2.99c-.14-.46-.27-.96-.4-1.48a2.25 2.25 0 01-1.83-1.64l-.26-.97a2.25 2.25 0 114.35-1.16l.26.96c.22.82-.05 1.66-.62 2.21.14.54.28 1.04.42 1.5A7.2 7.2 0 0040.03 36c.33.41.68.6 1.19.6.59 0 1.47-.25 2.78-.97zm1.75-.25a4 4 0 014-4h16.5a4 4 0 014 4v16.5a4 4 0 01-4 4h-16.5a4 4 0 01-4-4z"
          />
        </g>
      </g></svg>
  {/if}
{/if}
