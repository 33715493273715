<script>
  import { scaleFadeIn, scaleFadeOut } from "../helpers/transitions.js";
  import { theme } from "../../plugins/stores.js";
  import Profile from "./Profile.svelte";
  import Menu from "../Menu.svelte";

  export let auth;
  export let user;

  let isMenuOpen = false;
  let signedInMenuOptions = [
    {
      text: "Dark Mode",
      onClick: changeTheme,
      icon: "dark",
      isChecked: $theme == "theme-dark",
    },
    {
      text: "Sign Out",
      onClick: signOut,
      icon: "signout",
    },
  ];

  function closeMenu() {
    isMenuOpen = false;
  }

  function toggleMenu() {
    isMenuOpen = !isMenuOpen;
  }

  function signOut() {
    auth.signOut();
    closeMenu();
  }

  function changeTheme() {
    let bodyClassList = window.document.body.classList;
    if ($theme == "theme-dark") {
      $theme = "theme-light";
      bodyClassList.add("theme-light");
      bodyClassList.remove("theme-dark");
    } else {
      $theme = "theme-dark";
      bodyClassList.add("theme-dark");
      bodyClassList.remove("theme-light");
    }
  }
</script>

<div class="flex flex-col">
  <Profile {toggleMenu} {user} />
  {#if isMenuOpen}
    <div
      in:scaleFadeIn|local
      out:scaleFadeOut|local
      class="relative z-40 transform origin-top-right"
    >
      <div class="absolute right-0 top-0 mt-2">
        <Menu itemList={signedInMenuOptions} {closeMenu} />
      </div>
    </div>
  {/if}
</div>
