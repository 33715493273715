<script>
  import Card from "./Card.svelte";
  import AddCard from "./AddCard.svelte";
  import Icon from "./../Icon.svelte";
  import { Collection, User } from "sveltefire";
  import { createEventDispatcher } from "svelte";
  import { cardOut, cardIn } from "../helpers/transitions.js";
  import confetti from "canvas-confetti";
  import { tick } from "svelte";
  import { showLearnedCards, theme } from "./../../plugins/stores.js";

  export let deck;
  export let deleteDeck;
  export let markDeckLearned;
  export let markDeckNotLearned;
  export let openDeckNameModal;
  export let serverTimestamp;
  export let toggleLearnedCards;

  let cardList = [];
  let modedCardList = [];
  let modedCardListSlice = [];
  let cardInShouldAnimate = false;
  let confettiCanvas;
  let shouldConfettiShoot = true;
  let canvasWidth;
  let canvasHeight;
  let cardLearnedLoading = false;
  let cardDeletionLoading = false;
  let deckSettingsList;
  const dispatch = createEventDispatcher();

  $: deckSettingsList = [
    {
      header: "Filter",
    },
    {
      text: "Show Learned Cards",
      isChecked: $showLearnedCards,
      onClick: () => {
        toggleLearnedCards();
      },
    },
    {
      divider: true,
    },
    {
      header: "Manage",
    },
    {
      text: "Rename Deck",
      onClick: () => {
        openDeckNameModal();
      },
      icon: "edit",
    },
    ...(deck.learned
      ? [
          {
            text: "Mark as Not Learned",
            onClick: () => {
              markDeckNotLearned(deck.ref);
            },
            icon: "rewind",
          },
        ]
      : [
          {
            text: "Mark as Learned",
            onClick: () => {
              markDeckLearned(deck.ref);
            },
            icon: "tick-badge",
            color: "text-c-success",
          },
        ]),
    {
      text: "Delete Deck",
      onClick: () => {
        deleteDeck(deck.ref);
      },
      icon: "trashcan",
      color: "text-c-danger",
    },
  ];

  $: modedCardList = cardList;
  $: modedCardListSlice = modedCardList?.slice(-3);
  $: modedCardListSliceLast = modedCardListSlice?.length - 1;
  $: if (deckSettingsList) {
    dispatch("listupdate", { list: deckSettingsList });
  }

  async function nextCard() {
    modedCardList = modedCardList.slice(0, -1);
    if (modedCardList.length == 0 && cardList.length >= 3 && shouldConfettiShoot) {
      await tick();
      let canvasConfetti = confetti.create(confettiCanvas, {
        resize: true,
        useWorker: true,
      });
      canvasConfetti({
        origin: {
          y: 1.3,
        },
        particleCount: 100,
        startVelocity: 55,
        colors: ["#9580FF", "#FF7373", "#96FFAF"],
      });
      shouldConfettiShoot = false;
    }
  }

  async function deleteCard(ref) {
    try {
      cardDeletionLoading = true;
      await ref.update({ hidden: true });
      cardDeletionLoading = false;
    } catch (error) {
      cardDeletionLoading = false;
      console.log(error);
    }
  }

  async function markCardLearned(ref) {
    try {
      cardLearnedLoading = true;
      await ref.update({ learned: true });
      cardLearnedLoading = false;
    } catch (error) {
      cardLearnedLoading = false;
      console.log(error);
    }
  }

  async function markCardNotLearned(ref) {
    try {
      cardLearnedLoading = true;
      await ref.update({ learned: false });
      cardLearnedLoading = false;
    } catch (error) {
      cardLearnedLoading = false;
      console.log(error);
    }
  }

  function startOver() {
    cardInShouldAnimate = true;
    modedCardList = cardList;
    setTimeout(() => {
      cardInShouldAnimate = false;
    }, 200);
    shouldConfettiShoot = true;
  }

  function handleKeyup(event) {
    let keyCode = event.keyCode;
    if (keyCode == "40") {
      if (modedCardList.length > 0) {
        nextCard();
      } else if (modedCardList.length == 0) {
        startOver();
      }
    }
  }
  async function updateLastOpened() {
    try {
      await deck.ref.update({ lastOpenedAt: serverTimestamp });
    } catch (error) {
      console.log(error);
    }
  }
  let cardsQuery = (userUid) => {
    return (ref) =>
      ref
        .where("uid", "==", userUid)
        .where("hidden", "==", false)
        .where("learned", "==", $showLearnedCards)
        .orderBy("createdAt");
  };
  $: if ($showLearnedCards) {
    cardsQuery = (userUid) => {
      return (ref) =>
        ref.where("uid", "==", userUid).where("hidden", "==", false).orderBy("createdAt");
    };
  } else {
    cardsQuery = (userUid) => {
      return (ref) =>
        ref
          .where("uid", "==", userUid)
          .where("hidden", "==", false)
          .where("learned", "==", false)
          .orderBy("createdAt");
    };
  }
  updateLastOpened();
</script>

<svelte:window on:keyup={handleKeyup} />

<User let:user persist={localStorage}>
  <Collection
    path={`decks/${deck.id}/cards`}
    query={cardsQuery(user.uid)}
    on:data={async (e) => {
      cardList = e.detail.data;
    }}
    let:ref
  >
    <div class="flex flex-col">
      <!-- Add Card -->
      <AddCard {ref} />
      <!-- Spacer -->
      <div class="h-7vw+4vh min-h-12 max-h-16 md:h-16" />
      <!-- Card List Loaded -->
      <div
        class="w-90vw h-40vh min-h-48 max-w-84 max-h-84 md:w-84 md:h-84 relative flex justify-center
          items-center"
      >
        {#if cardList?.length > 0}
          {#each modedCardListSlice as card, i (card.id)}
            <div
              in:cardIn|local={{ duration: cardInShouldAnimate ? 400 : 0 }}
              out:cardOut|local
              class="w-full h-full transform absolute left-0 top-0 transition-all duration-250
                ease-out {i == modedCardListSliceLast - 1 ? '-translate-y-1/10 scale-90 deck-middle' : i == modedCardListSliceLast - 2 ? '-translate-y-2/10 scale-80 deck-back' : 'deck-front'}"
            >
              <Card
                {...card}
                {nextCard}
                {markCardLearned}
                {markCardNotLearned}
                {deleteCard}
                show={modedCardListSliceLast == i}
              />
            </div>
          {:else}
            <div
              bind:clientWidth={canvasWidth}
              bind:clientHeight={canvasHeight}
              class="w-full h-full flex flex-col items-center justify-center pb-8 md:pb-10 relative"
            >
              <p class="text-lg text-center text-c-text-50">You're done.</p>
              <p class="text-3xl font-bold text-center -mt-1">Congrats!</p>
              <button
                on:click={startOver}
                class="bg-c-text text-c-bg text-lg font-bold rounded-md px-12 py-2
                  hover:bg-c-primary hover:text-white transition-colors duration-250 ease-out
                  shadow-button relative mt-6"
              >
                Start Over
              </button>
              <canvas
                bind:this={confettiCanvas}
                class="absolute left-0 top-0 pointer-events-none"
                width={canvasWidth}
                height={canvasHeight}
              />
            </div>
          {/each}
        {:else}
          <div class="w-full h-full flex flex-col items-center px-4 text-center">
            <div class="w-1/2 animation-arrow">
              <Icon type="arrow-up" />
            </div>
            <p class="opacity-50 mt-5 text-sm">There are no cards in this deck.</p>
            <p class="text-xl font-bold">Create a card to start.</p>
          </div>
        {/if}
      </div>
    </div>
    <!-- Card List Loading -->
    <div class="flex flex-col" slot="loading">
      <!-- Add Card -->
      <AddCard loading={true} />
      <!-- Spacer -->
      <div class="h-7vw+4vh min-h-12 max-h-16 md:h-16" />
      <!-- Placeholder Cards -->
      <div class="w-90vw h-40vh min-h-48 max-w-84 max-h-84 md:w-84 md:h-84 relative">
        {#each [1, 2, 3] as card, i}
          <div
            class="{i == 1 ? '-translate-y-1/10 scale-90' : i == 0 ? '-translate-y-2/10 scale-80' : ''}
              w-full h-full absolute left-0 top-0 transform"
          >
            <Card placeholderText="loading" />
          </div>
        {/each}
      </div>
    </div>
    <!-- Card List Error -->
    <div class="flex flex-col" slot="fallback">
      <div
        class="w-90vw h-90vw max-w-84 max-h-84 md:w-84 md:h-84 relative flex justify-center
          items-center"
      >
        <div class="flex flex-col items-center justify-center py-6 md:pb-8">
          <p class="text-3xl font-bold text-center">Oops...</p>
          <p class="text-lg text-center text-c-text-50">An error occured.</p>
          <a
            href="/"
            class="bg-c-text text-c-bg text-lg font-bold rounded-md px-6 py-2 hover:bg-c-primary
              hover:text-white transition-colors duration-250 ease-out shadow-button relative mt-6"
          >
            Refresh the Page
          </a>
        </div>
      </div>
    </div>
  </Collection>
</User>

<style>
  .animation-arrow {
    animation-name: arrow;
    animation-duration: 0.5s;
    animation-direction: alternate;
    animation-iteration-count: 8;
    animation-timing-function: cubic-bezier(0.4, 0, 0.5, 1);
    transform: translateY(0rem);
    transform-origin: bottom;
  }
  @keyframes arrow {
    0% {
      transform: translateY(0rem);
    }
    100% {
      transform: translateY(-1.5rem);
    }
  }
</style>
