<script>
  import Navbar from "./components/Navbar.svelte";
  import MainContainer from "./components/MainContainer.svelte";
  import { theme } from "./plugins/stores.js";
  import { firebaseConfig } from "./plugins/config.js";

  import { FirebaseApp } from "sveltefire";
  import firebase from "firebase/app";
  import "firebase/firestore";
  import "firebase/auth";
  import "firebase/firebase-analytics";

  document.body.classList.add($theme, "bg-c-bg", "text-c-text");
  firebase.initializeApp(firebaseConfig);

  let innerHeight;
</script>

<svelte:window bind:innerHeight />

<FirebaseApp {firebase} analytics>
  <main style="height:{innerHeight}px" class="w-full flex flex-col wrap-nicely overflow-hidden">
    <Navbar />
    <div
      class="container-1280 flex-1 flex flex-col px-2 pt-4 pb-8 md:px-8 md:pt-6 md:pb-20
        overflow-y-scroll no-scroll-bar"
    >
      <MainContainer />
    </div>
  </main>
</FirebaseApp>
