<script>
  import { fade } from "svelte/transition";
  export let delayed = false;
  export let color = "bg-c-text-50";
</script>

<div in:fade={{ duration: 200, delay: delayed ? 400 : 0 }} class="spinner">
  <div class="double-bounce1 {color}" />
  <div class="double-bounce2 {color}" />
</div>

<style>
  .spinner {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0);
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
</style>
