<script>
  import Spinner from "./../Spinner.svelte";
  import ClickOutside from "../helpers/ClickOutside.svelte";
  import StatusBadge from "./StatusBadge.svelte";
  import { scaleFadeIn, scaleFadeOut } from "../helpers/transitions.js";
  import { tick } from "svelte";
  import { createEventDispatcher } from "svelte";

  export let text;
  export let onClick;
  export let deck;
  export let ref;
  export let user;
  export let serverTimestamp;

  let deckCreationLoading = false;
  let isDeckNameInputVisible = false;
  let deckName;
  let deckNameInput;
  const dispatch = createEventDispatcher();

  function handleKeydown(event) {
    let keyCode = event.keyCode;
    if (keyCode == "13") {
      if (deckName && deckName?.length > 0) {
        createDeck();
      }
    }
  }

  async function createDeck() {
    try {
      deckCreationLoading = true;
      isDeckNameInputVisible = false;
      let newDeckRef = await ref.add({
        uid: user.uid,
        name: deckName,
        createdAt: serverTimestamp,
        lastOpenedAt: serverTimestamp,
        hidden: false,
        learned: false,
      });
      console.log("NEW DECK REF IS", newDeckRef);
      dispatch("deckcreated", { newDeckRef });
      deckCreationLoading = false;
      deckName = null;
    } catch (error) {
      deckCreationLoading = false;
      console.log(error);
    }
  }
</script>

{#if onClick || (ref && user && serverTimestamp)}
  {#if !(ref && user && serverTimestamp)}
    <!-- Normal Deck List Item -->
    <button
      on:click={onClick}
      class="w-40vw h-40vw max-w-64 max-h-64 md:w-64 md:h-64 flex flex-col justify-center
        items-center bg-c-bg-card hover:bg-c-border-card border border-c-border-card rounded-lg m-2
        md:m-3 transition-all duration-250 ease-out relative overflow-hidden px-5 py-7 md:p-10"
    >
      <!-- Learned Status Badge -->
      {#if deck?.learned == true}
        <StatusBadge
          learned={deck.learned}
          class="absolute left-0 top-0 ml-1_5 mt-1_5 w-8 h-8 md:w-10 md:h-10"
        />
      {/if}
      <div class="flex flex-col items-center overflow-y-scroll no-scroll-bar">
        <div class="my-auto">
          <!-- Normal Deck List Item -->
          <span
            class="{deck?.learned ? 'text-c-success' : 'text-c-text'} text-xl md:text-2xl font-bold text-center"
          >{deck.name ? deck.name : text ? text : 'Pass text'}</span>
        </div>
      </div>
    </button>
  {:else}
    <!-- New Deck -->
    <ClickOutside
      on:clickoutside={() => {
        if (deckNameInput && !deckCreationLoading && isDeckNameInputVisible && ref && user) {
          isDeckNameInputVisible = false;
        }
      }}
    >
      <div
        class="{isDeckNameInputVisible && !deckCreationLoading ? 'w-80vw+3 h-60vw max-w-128 max-h-96' : 'w-40vw max-w-64  h-40vw max-h-64'}
          md:w-64 md:h-64 bg-c-bg-card border border-c-border-card rounded-lg transition-all duration-250
          ease-out relative overflow-hidden m-2 md:m-3"
      >
        {#if !isDeckNameInputVisible}
          <button
            in:scaleFadeOut|local={{ duration: 250 }}
            on:click={() => {
              if (!deckCreationLoading) {
                setTimeout(async () => {
                  isDeckNameInputVisible = true;
                  await tick();
                  setTimeout(() => {
                    deckNameInput.focus();
                  }, 50);
                }, 0);
              }
            }}
            disabled={isDeckNameInputVisible || deckCreationLoading}
            class="w-full h-full flex flex-col justify-center items-center px-5 py-7 md:p-10
              hover:bg-c-border-card transition-colors duration-250 ease-out"
          >
            {#if !isDeckNameInputVisible}
              <svg
                class="w-12 h-12 text-c-text-30"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                /></svg>
            {/if}
          </button>
        {:else}
          <div
            in:scaleFadeIn|local={{ duration: 250 }}
            out:scaleFadeOut|local={{ duration: 200 }}
            class="w-full h-full flex flex-col justify-center items-center px-5 py-7 relative"
          >
            <div class="w-full relative">
              <input
                bind:value={deckName}
                bind:this={deckNameInput}
                disabled={deckCreationLoading || !isDeckNameInputVisible}
                on:keydown={handleKeydown}
                placeholder=" "
                class="w-full text-xl font-bold px-3 py-1_5 transition-all duration-200 ease-out"
              />
              <label
                for="deckNameInput"
                class="absolute left-0 bottom-0 transition-all duration-200 ease-out
                  pointer-events-none"
              >
                Deck Name
              </label>
            </div>
            {#if deckName != null && deckName?.length > 0}
              <button
                on:click={createDeck}
                in:scaleFadeIn|local
                out:scaleFadeOut|local
                class="absolute bottom-0 mb-4 mx-auto bg-c-text text-c-bg rounded-md px-6 py-1
                  font-bold hover:bg-c-primary hover:text-white transition-colors duration-250
                  ease-out shadow-md origin-bottom"
              >
                Create Deck
              </button>
            {/if}
          </div>
        {/if}
        {#if deckCreationLoading}
          <div
            class="absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center
              bg-c-bg-card rounded-lg"
          >
            <div class="w-16 h-16">
              <Spinner />
            </div>
          </div>
        {/if}
      </div>
    </ClickOutside>
  {/if}
{:else}
  <div
    class="w-40vw h-40vw max-w-64 max-h-64 md:w-64 md:h-64 flex flex-col justify-center items-center
      bg-c-bg-card hover:bg-c-border-card border border-c-border-card rounded-lg m-2 md:m-3
      transition-colors duration-250 ease-out"
  >
    <span
      class="text-transparent bg-c-text text-xl md:text-2xl font-bold mx-2 md:mx-4 text-center px-4
        rounded-full opacity-50 opacity-animation pointer-events-none"
    >{text}</span>
  </div>
{/if}

<style>
  input {
    background-color: var(--c-bg-30);
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: var(--c-bg-30);
  }
  input:focus,
  input:focus:not(:placeholder-shown) {
    border-color: var(--c-primary);
  }
  input:not(:placeholder-shown) {
    border-color: var(--c-bg-30);
  }
  label {
    font-size: 1.25rem;
    opacity: 0.5;
    color: var(--c-text);
    margin-left: 0.75rem;
    margin-bottom: 0.375rem;
  }
  input:focus + label {
    margin-bottom: 3rem;
    font-size: 1rem;
    font-weight: 500;
    opacity: 1;
    color: var(--c-primary);
  }
  input:not(:placeholder-shown) + label {
    margin-bottom: 3rem;
    font-size: 1rem;
    font-weight: 500;
    opacity: 1;
  }
</style>
