<script>
  import { theme } from "../plugins/stores";
  import Logo from "./Logo.svelte";
  import Sign from "./sign/Sign.svelte";
</script>

<nav class="w-full px-3 py-2 md:py-4 md:px-6">
  <div class="container-1280 flex justify-between items-center">
    <a href="/" class="group p-1">
      <Logo
        class="h-7 w-auto"
        theme={$theme}
        classInner="group-hover:text-c-primary group-focus:text-c-primary
        transition-colors duration-250 ease-out"
      />
    </a>
    <Sign />
  </div>
</nav>
