<script>
  export let onClick;
  export let side;
  export let show = true;
</script>

<button
  on:click={onClick}
  class="{side == 'left' || side == 'right' ? 'h-full w-16' : 'w-full h-16'}
    {side == 'left' ? 'left-0 top-0 rounded-l-lg' : side == 'right' ? 'right-0 top-0 rounded-r-lg' : side == 'top' ? 'left-0 top-0 rounded-t-lg' : side == 'bottom' ? 'left-0 bottom-0 rounded-b-lg' : ''}
    {show ? 'opacity-100 hover:bg-c-text-05' : 'opacity-0 pointer-events-none'} flex items-center justify-center
    absolute transition-all duration-200 ease-out"
>
  <svg
    class="w-6 h-6 transform text-c-text-20 {side == 'left' ? 'rotate-90 mr-6' : side == 'right' ? '-rotate-90 ml-6' : side == 'top mb-6' ? 'rotate-180' : 'mt-6'}"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" /></svg>
</button>
