<script>
  import Icon from "./Icon.svelte";
  import ClickOutside from "./helpers/ClickOutside.svelte";

  export let closeMenu;
  export let itemList = [];
  export { classes as class };

  let render = false;
  let classes;

  setTimeout(() => {
    render = true;
  }, 0);
</script>

{#if render}
  <ClickOutside on:clickoutside={closeMenu}>
    <div
      class="{classes ? classes : ''} flex flex-col bg-c-bg-card border border-c-border-card rounded-lg
        shadow-menu py-3"
    >
      {#each itemList as item}
        {#if item.header}
          <div class="flex w-full font-bold text-c-text text-2xl rounded-md py-1 px-6">
            <h2 class="whitespace-no-wrap">{item.header}</h2>
          </div>
        {:else if item.divider}
          {#if item.divider}
            <div class="w-full h-px bg-c-border-card mt-4 mb-5" />
          {/if}
        {:else if item.isChecked == null}
          <div class="flex flex-col px-3">
            <button
              on:click={async () => {
                item.onClick();
                closeMenu();
              }}
              class="w-full flex items-center min-w-32 font-medium text-c-text
                hover:bg-c-border-card transition-all duration-300 ease-out px-4 py-3 rounded-md"
            >
              {#if item.icon}
                <div class="h-6 w-6 mr-3">
                  <Icon color={item.color ? item.color : 'text-c-text'} type={item.icon} />
                </div>
              {/if}
              <p class="whitespace-no-wrap mr-3 {item.color ? item.color : 'text-c-text'}">
                {item.text}
              </p>
            </button>
          </div>
        {:else}
          <div class="flex flex-col px-3">
            <button
              on:click={async () => {
                item.onClick();
                item.isChecked = !item.isChecked;
              }}
              class="w-full flex items-center min-w-32 font-medium text-c-text
                hover:bg-c-border-card transition-all duration-300 ease-out px-4 py-3 rounded-md"
            >
              {#if item.icon}
                <div class="h-6 w-6 mr-3">
                  <Icon type={item.icon} />
                </div>
              {/if}
              <p class="whitespace-no-wrap mr-3">{item.text}</p>
              <!-- Rounded switch -->
              <div class="ml-auto">
                <label class="switch ml-2">
                  <input type="checkbox" disabled checked={item.isChecked} />
                  <span class="slider-round" />
                </label>
              </div>
            </button>
          </div>
        {/if}
      {/each}
    </div>
  </ClickOutside>
{/if}

<style>
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider-round {
    background-color: var(--c-primary);
  }

  input:focus + .slider-round {
    box-shadow: 0 0 1px var(--c-primary);
  }

  input:checked + .slider-round:before {
    transform: translateX(24px);
  }

  /* Rounded sliders */
  .slider-round {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--c-text-20);
    -webkit-transition: 0.4s;
    transition: all 0.3s ease-out;
    border-radius: 18px;
  }
  .slider-round:before {
    border-radius: 50%;
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: all 0.3s ease-out;
  }
</style>
