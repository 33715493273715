<script>
  import SideButton from "./../buttons/SideButton.svelte";
  import Icon from "./../Icon.svelte";
  import Menu from "./../Menu.svelte";
  import StatusBadge from "./StatusBadge.svelte";
  import {
    turnFrontCardIn,
    turnFrontCardOut,
    turnBackCardIn,
    turnBackCardOut,
    scaleFadeIn,
    scaleFadeOut,
  } from "../helpers/transitions.js";
  import { fade } from "svelte/transition";
  import { swipe } from "../helpers/swipe";
  import { isAddCardOpen } from "./../../plugins/stores.js";

  export let frontText;
  export let backText;
  export let ref;
  export let learned;
  export let placeholderText;
  export let nextCard;
  export let deleteCard;
  export let markCardLearned;
  export let markCardNotLearned;
  export let show;

  let flipped = false;
  let cardSettingsItemList;
  let isCardSettingsOpen = false;

  $: cardSettingsItemList = [
    ...(learned
      ? [
          {
            text: "Mark as Not Learned",
            onClick: () => {
              flipped = false;
              markCardNotLearned(ref);
            },
            icon: "rewind",
          },
        ]
      : [
          {
            text: "Mark as Learned",
            onClick: () => {
              flipped = false;
              markCardLearned(ref);
            },
            icon: "tick-badge",
            color: "text-c-success",
          },
        ]),
    {
      text: "Delete Card",
      onClick: async () => {
        try {
          await deleteCard(ref);
        } catch (error) {
          console.log(error);
        }
      },
      icon: "trashcan",
      color: "text-c-danger",
    },
  ];

  function flipCard() {
    flipped = !flipped;
  }
  function closeCardSettings() {
    isCardSettingsOpen = false;
  }

  function handleKeyup(event) {
    let keyCode = event.keyCode;
    if ((keyCode == "39" || keyCode == "37") && show && !$isAddCardOpen) {
      flipCard();
    }
  }
</script>

<svelte:window on:keyup={handleKeyup} />

{#if !placeholderText}
  <div
    use:swipe
    on:swiperight={show ? flipCard : null}
    on:swipeleft={show ? flipCard : null}
    on:swipedown={show ? nextCard : null}
    class="w-full h-full perspective"
  >
    <!-- Front Side -->
    {#if !flipped}
      <div
        in:turnFrontCardIn|local
        out:turnFrontCardOut|local
        class="absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center
          bg-c-bg-card rounded-lg border border-c-border-card"
      >
        {#if show}
          <div class="flex flex-col items-center mx-10 my-10 overflow-scroll no-scroll-bar">
            <h1
              class="{learned ? 'text-c-success' : 'text-c-text'} text-2xl font-bold text-center my-auto"
            >
              {frontText}
            </h1>
          </div>
          <SideButton side="left" onClick={flipCard} show={!flipped} />
          <SideButton side="right" onClick={flipCard} show={!flipped} />
          <SideButton side="bottom" onClick={nextCard} show={!flipped} />
          <!-- Learned Status Badge -->
          <div
            transition:fade|local={{ duration: 200 }}
            class="absolute left-0 top-0 ml-1_5 mt-1_5 md:ml-2 md:mt-2"
          >
            {#if learned == true}
              <StatusBadge class="w-10 h-10" {learned} />
            {/if}
          </div>
        {/if}
      </div>
    {:else}
      <!-- Back Side -->
      <div
        in:turnBackCardIn|local
        out:turnBackCardOut|local
        class="absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center
          bg-card-back rounded-lg border border-c-border-card"
      >
        {#if show}
          <div class="flex flex-col items-center mx-10 my-10 overflow-scroll no-scroll-bar">
            <h1
              class="{learned ? 'text-c-success' : 'text-c-text'} text-xl font-medium text-center my-auto"
            >
              {backText}
            </h1>
          </div>
          <SideButton side="left" onClick={flipCard} show={flipped} />
          <SideButton side="right" onClick={flipCard} show={flipped} />
          <SideButton side="bottom" onClick={nextCard} show={flipped} />
          <!-- Learned Status Badge -->
          <div
            transition:fade|local={{ duration: 200 }}
            class="absolute left-0 top-0 ml-1_5 mt-1_5 md:ml-2 md:mt-2"
          >
            {#if learned == true}
              <StatusBadge class="w-10 h-10" {learned} />
            {/if}
          </div>
          <!-- Three dot menu -->
          <div class="absolute right-0 top-0 flex flex-col">
            <!-- Three dot button -->
            <button
              on:click={() => {
                isCardSettingsOpen = !isCardSettingsOpen;
              }}
              class="w-16 h-16 group flex justify-end items-start"
            >
              <div
                class="{isCardSettingsOpen ? '' : 'group-hover:bg-c-text-10'} w-12 h-12 p-1_5 rounded-full
                  mt-2 mr-1 duration-300 ease-out"
              >
                <Icon
                  type="threedot"
                  color={isCardSettingsOpen ? 'text-c-primary' : 'text-c-text-40'}
                  class="transform duration-300 {isCardSettingsOpen ? 'rotate-90' : 'rotate-0'}"
                />
              </div>
            </button>
            <!-- Card settings -->
            {#if isCardSettingsOpen}
              <div
                in:scaleFadeIn|local
                out:scaleFadeOut|local
                class="absolute right-0 top-0 transform origin-top-right pt-2 pr-2"
              >
                <Menu itemList={cardSettingsItemList} closeMenu={closeCardSettings} />
              </div>
            {/if}
          </div>
        {/if}
      </div>
    {/if}
  </div>
{:else}
  <div class="w-full h-full">
    <div
      class="absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center
        bg-c-bg-card border border-c-border-card rounded-lg px-12"
    >
      <h1
        class="text-2xl font-bold text-transparent bg-c-text text-center px-4 rounded-full
          opacity-50 opacity-animation pointer-events-none"
      >
        {placeholderText}
      </h1>
    </div>
  </div>
{/if}

<style>
  .bg-card-back {
    background: repeating-linear-gradient(
      45deg,
      var(--c-bg-card),
      var(--c-bg-card) 1rem,
      var(--c-bg-card-sec) 1rem,
      var(--c-bg-card-sec) 2rem
    );
  }
  .perspective {
    perspective: 100rem;
  }
</style>
