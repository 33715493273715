<script>
  import Modal from "./Modal.svelte";
  import Illustration from "./Illustration.svelte";
  import Deck from "./deck/Deck.svelte";
  import DeckListItem from "./deck/DeckListItem.svelte";
  import Icon from "./Icon.svelte";
  import Menu from "./Menu.svelte";
  import Spinner from "./Spinner.svelte";
  import { Collection, User } from "sveltefire";
  import { getContext } from "svelte";
  import { scaleFadeIn, scaleFadeOut } from "./helpers/transitions.js";
  import { fade } from "svelte/transition";
  import StatusBadge from "./deck/StatusBadge.svelte";
  import { showLearnedDecks, showLearnedCards } from "./../plugins/stores.js";
  import { theme } from "../plugins/stores";

  const app = getContext("firebase").getFirebase();
  const googleProvider = new app.auth.GoogleAuthProvider();
  let serverTimestamp = new app.firestore.FieldValue.serverTimestamp();

  let isDeckOpen = false;
  let decksData = [];
  let currentDeck;
  let isDeckSettingsOpen = false;
  let isDeckListSettingsOpen = false;
  let deckSettingsList;
  let deckDeletionLoading = false;
  let markDeckLearnedLoading = false;
  let markDeckNotLearnedLoading = false;
  let isDeckNameModalOpen = false;
  let newDeckName;
  let newDeckNameInput;
  let isChangeDeckNameLoading = false;

  let deckListMenuItemList = [
    {
      header: "Filter",
    },
    {
      text: "Show Learned Decks",
      isChecked: $showLearnedDecks,
      onClick: () => {
        $showLearnedDecks = !$showLearnedDecks;
        if ($showLearnedDecks) {
          $showLearnedCards = true;
        } else {
          $showLearnedCards = false;
        }
      },
    },
  ];
  async function goBack() {
    isDeckOpen = false;
    currentDeck = null;
    deckSettingsList = null;
    isDeckSettingsOpen = false;
  }
  function showDeck(deck) {
    isDeckListSettingsOpen = false;
    isDeckSettingsOpen = false;
    currentDeck = deck;
    isDeckOpen = true;
  }
  async function deleteDeck(ref) {
    try {
      deckDeletionLoading = true;
      isDeckOpen = false;
      isDeckSettingsOpen = false;
      currentDeck = null;
      await ref.update({ hidden: true });
      deckDeletionLoading = false;
    } catch (error) {
      deckDeletionLoading = false;
      console.log(error);
    }
  }

  async function markDeckLearned(ref) {
    try {
      markDeckLearnedLoading = true;
      isDeckSettingsOpen = false;
      if (!$showLearnedDecks) {
        isDeckOpen = false;
        currentDeck = null;
      }
      await ref.update({ learned: true });
      if ($showLearnedDecks) {
        for (let i = 0; i < decksData.length; i++) {
          if (ref.id == decksData[i].id) {
            currentDeck = decksData[i];
            break;
          }
        }
      }
      markDeckLearnedLoading = false;
    } catch (error) {
      markDeckLearnedLoading = false;
      console.log(error);
    }
  }
  async function markDeckNotLearned(ref) {
    try {
      markDeckNotLearnedLoading = true;
      isDeckSettingsOpen = false;
      await ref.update({ learned: false });
      for (let i = 0; i < decksData.length; i++) {
        if (ref.id == decksData[i].id) {
          currentDeck = decksData[i];
          break;
        }
      }
      markDeckNotLearnedLoading = false;
    } catch (error) {
      markDeckNotLearnedLoading = false;
      console.log(error);
    }
  }
  async function changeDeckName(ref, newName) {
    try {
      isChangeDeckNameLoading = true;
      await ref.update({ name: newName });
      for (let i = 0; i < decksData.length; i++) {
        if (ref.id == decksData[i].id) {
          currentDeck = decksData[i];
          break;
        }
      }
      isChangeDeckNameLoading = false;
      isDeckNameModalOpen = false;
      newDeckName = null;
      newDeckNameInput = null;
    } catch (error) {
      isChangeDeckNameLoading = false;
      console.log(error);
    }
  }
  function openDeckNameModal() {
    setTimeout(() => {
      isDeckNameModalOpen = true;
      setTimeout(() => {
        if (newDeckNameInput) {
          newDeckName = currentDeck?.name;
          newDeckNameInput.focus();
        }
      }, 150);
    }, 0);
  }
  async function handleListUpdate(event) {
    let { list } = event.detail;
    deckSettingsList = list;
  }
  function closeDeckSettings() {
    isDeckSettingsOpen = false;
  }
  function closeDeckListMenu() {
    isDeckListSettingsOpen = false;
  }
  function toggleLearnedCards() {
    $showLearnedCards = !$showLearnedCards;
  }
  let decksQuery = (userUid) => {
    return (ref) =>
      ref
        .where("uid", "==", userUid)
        .where("hidden", "==", false)
        .where("learned", "==", false)
        .orderBy("lastOpenedAt", "desc");
  };
  $: if ($showLearnedDecks) {
    decksQuery = (userUid) => {
      return (ref) =>
        ref
          .where("uid", "==", userUid)
          .where("hidden", "==", false)
          .orderBy("lastOpenedAt", "desc");
    };
  } else {
    decksQuery = (userUid) => {
      return (ref) =>
        ref
          .where("uid", "==", userUid)
          .where("hidden", "==", false)
          .where("learned", "==", false)
          .orderBy("lastOpenedAt", "desc");
    };
  }
  function closeNewDeckNameModal() {
    isDeckNameModalOpen = false;
    newDeckName = null;
  }
  function handleDeckNameInputKeydown(event) {
    let keyCode = event.keyCode;
    if (keyCode == "13" && currentDeck.name != newDeckName && newDeckName) {
      changeDeckName(currentDeck.ref, newDeckName);
    }
  }
</script>

<User let:user let:auth persist={localStorage}>
  <div class="w-full flex flex-col my-auto overflow-hidden relative">
    <!---- Navigation and Header ---->
    <div class="w-full flex justify-between items-center px-1 mb-3">
      {#if !isDeckOpen}
        <div class="w-12 h-12" />
      {:else}
        <!-- Back Button -->
        <button
          on:click={goBack}
          class="w-12 h-12 p-2 rounded-full hover:bg-c-text-10 transition-colors duration-250
            ease-out"
        >
          <Icon type="back" />
        </button>
      {/if}
      <div class="flex flex-1 justify-center items-center overflow-hidden mx-3">
        <!-- Status Badge -->
        {#if currentDeck && !isDeckNameModalOpen}
          {#if markDeckLearnedLoading || markDeckNotLearnedLoading}
            <div class="w-7 h-7 md:w-8 md:h-8 mr-1_5 md:mr-2 mb-1 md:mb-0_5">
              <Spinner color={markDeckNotLearnedLoading ? 'bg-c-success-50' : 'bg-c-text-50'} />
            </div>
          {:else}
            <StatusBadge
              learned={currentDeck.learned}
              class="w-7 h-7 md:w-8 md:h-8 mr-1_5 md:mr-2 mb-1 md:mb-0_5"
            />
          {/if}
        {/if}
        <!-- Decks Header or Name -->
        <h1
          class="{currentDeck?.learned ? 'text-c-success' : 'text-c-text'} text-2xl md:text-3xl font-bold
            transition-opacity duration-200 ease-out whitespace-no-wrap overflow-x-scroll no-scroll-bar {isDeckOpen && isDeckNameModalOpen ? 'opacity-0' : ''}"
        >
          {!isDeckOpen ? 'Decks' : currentDeck?.name}
        </h1>
      </div>
      {#if !isDeckOpen}
        <!-- Deck List Menu -->
        <div class="relative">
          <button
            on:click={() => (isDeckListSettingsOpen = !isDeckListSettingsOpen)}
            class="w-12 h-12 p-2 rounded-full hover:bg-c-text-10 transition-colors duration-250
              ease-out"
          >
            <Icon
              type="settings"
              color={isDeckListSettingsOpen ? 'text-c-primary' : 'text-c-text'}
              class="transform {isDeckListSettingsOpen ? 'rotate-90' : ''}"
            />
          </button>
          {#if isDeckListSettingsOpen}
            <div in:scaleFadeIn|local out:scaleFadeOut|local class="relative z-40 origin-top-right">
              <div class="absolute right-0 top-0 mt-1">
                <Menu itemList={deckListMenuItemList} closeMenu={closeDeckListMenu} />
              </div>
            </div>
          {/if}
        </div>
      {:else}
        <!-- Settings -->
        <div class="relative">
          <button
            on:click={() => (isDeckSettingsOpen = !isDeckSettingsOpen)}
            class="w-12 h-12 p-2 rounded-full hover:bg-c-text-10 transition-colors duration-250
              ease-out"
          >
            <Icon
              type="settings"
              color={isDeckSettingsOpen ? 'text-c-primary' : 'text-c-text'}
              class="transform {isDeckSettingsOpen ? 'rotate-90' : ''}"
            />
          </button>
          {#if isDeckSettingsOpen}
            <div in:scaleFadeIn|local out:scaleFadeOut|local class="relative z-40 origin-top-right">
              <div class="absolute right-0 top-0 mt-1">
                {#if deckSettingsList}
                  <Menu itemList={deckSettingsList} closeMenu={closeDeckSettings} />
                {/if}
              </div>
            </div>
          {/if}
        </div>
      {/if}
    </div>
    <!---- Deck List or Single Deck ---->
    <div class="flex flex-col relative overflow-hidden">
      <div
        class="w-full max-h-full overflow-y-scroll no-scroll-bar flex flex-wrap justify-center rounded-lg
          bg-c-bg-deck border border-c-border-deck py-6 md:px-8 md:py-12 relative my-auto"
      >
        <!---- Decks List Collection ---->
        <Collection
          path={'decks'}
          query={decksQuery(user.uid)}
          on:data={async (e) => {
            decksData = e.detail.data;
          }}
          let:ref
        >
          {#if !isDeckOpen}
            <div class="w-full flex flex-wrap justify-center">
              <!-- Add New Deck -->
              <DeckListItem
                {ref}
                {user}
                {serverTimestamp}
                text={'+'}
                on:deckcreated={(e) => {
                  let { newDeckRef } = e.detail;
                  let newDeck;
                  for (let i = 0; i < decksData.length; i++) {
                    if (newDeckRef.id == decksData[i].id) {
                      newDeck = decksData[i];
                      break;
                    }
                  }
                  showDeck(newDeck);
                }}
              />
              <!-- Deck List Loaded -->
              {#if decksData}
                {#each decksData as deck, i (deck.id)}
                  <DeckListItem {deck} onClick={() => showDeck(deck)} />
                {/each}
              {/if}
              <!-- If there are no decks -->
              {#if decksData && decksData.length < 1}
                <div
                  class="w-40vw h-40vw max-w-64 max-h-64 md:w-64 md:h-64 flex flex-col
                    justify-center items-center m-2 md:m-3 text-center"
                >
                  <div class="w-1/2 h-auto animation-arrow">
                    <Icon type="arrow-left" />
                  </div>

                  <p class="opacity-50 text-sm mx-3">You don't have any decks.</p>
                  <p class="text-lg font-bold mx-3 mb-5">Create a deck to start.</p>
                </div>
              {/if}
            </div>
          {:else}
            <!---- Single Deck ---->
            <div class="w-full flex flex-wrap justify-center">
              <Deck
                deck={currentDeck}
                on:listupdate={handleListUpdate}
                {deleteDeck}
                {markDeckLearned}
                {markDeckNotLearned}
                {serverTimestamp}
                {toggleLearnedCards}
                {openDeckNameModal}
              />
            </div>
          {/if}
          <!-- Deck List Loading -->
          <div class="w-full flex flex-wrap justify-center" slot="loading">
            <DeckListItem text={'+'} />
            <DeckListItem text={'Loading'} />
            <DeckListItem text={'Loading'} />
            <DeckListItem text={'Loading'} />
          </div>
          <!-- Deck List Error -->
          <div class="w-full flex flex-col justify-center items-center" slot="fallback">
            <p class="text-3xl font-bold text-center">Oops...</p>
            <p class="text-lg text-center text-c-text-50">An error occured.</p>
            <a
              href="/"
              class="bg-c-text text-c-bg text-lg font-bold rounded-md px-6 py-2 hover:bg-c-primary
                hover:text-white transition-colors duration-250 ease-out shadow-button relative mt-6"
            >
              Refresh the Page
            </a>
          </div>
        </Collection>
      </div>
      {#if (deckDeletionLoading || markDeckLearnedLoading || markDeckNotLearnedLoading) && !isDeckOpen}
        <div
          out:fade={{ duration: 150 }}
          class="absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center
            rounded-lg bg-c-bg-deck border border-c-border-deck z-40"
        >
          <div class="w-24 h-24 my-auto">
            <Spinner />
          </div>
        </div>
      {/if}
    </div>
  </div>
  <!-- Signed Out -->
  <div class="w-full flex flex-col my-auto overflow-hidden relative" slot="signed-out">
    <div
      class="w-full max-h-full overflow-y-scroll no-scroll-bar flex flex-col items-center rounded-lg bg-c-bg-deck
        border border-c-border-deck pt-7 pb-9 md:pt-10 md:pb-13 relative my-auto"
    >
      <h2 class="text-lg md:text-xl text-c-text-75">Simple & Beautiful</h2>
      <h1 class="text-4xl md:text-5xl font-bold -mt-1 md:-mt-2">Flashcards</h1>
      <div class="w-full bg-c-border-deck h-px mt-5 mb-8 md:mt-6 md:mb-11"><br /></div>
      <div class="w-full px-8 max-w-96">
        <Illustration type="welcome" theme={$theme} />
      </div>
      <p class="text-xl font-bold mt-6 text-center max-w-72 px-4">Sign in to continue.</p>
      <p class="text-c-text-50 text-center max-w-72 mt-1 px-4">
        No need to sign up. Just sign in with your Google account.
      </p>
      <button
        on:click={auth.signInWithPopup(googleProvider)}
        class="bg-c-text text-lg text-c-bg rounded-md px-5 py-1_5 font-medium hover:bg-c-primary
          hover:text-white transition-colors duration-250 ease-out shadow-button mt-5 mx-3"
      >
        Sign in with <span class="font-bold">Google</span>
      </button>
    </div>
  </div>
  <!-- New Deck Name Modal -->
  <Modal
    isOpen={isDeckNameModalOpen}
    closeModal={!isChangeDeckNameLoading ? closeNewDeckNameModal : null}
  >
    <div class="w-full px-4 md:px-6">
      <div class="w-full relative">
        <input
          bind:this={newDeckNameInput}
          bind:value={newDeckName}
          on:keydown={handleDeckNameInputKeydown}
          placeholder=" "
          disabled={isChangeDeckNameLoading}
          class="w-full text-xl font-bold px-3 py-1_5 transition-all duration-200 ease-out"
        />
        <label
          for="deckNameInput"
          class="absolute left-0 bottom-0 transition-all duration-200 ease-out pointer-events-none"
        >
          Deck Name
        </label>
      </div>
    </div>
    {#if currentDeck?.name != newDeckName && newDeckName}
      <button
        on:click={() => {
          if (!isChangeDeckNameLoading) {
            changeDeckName(currentDeck.ref, newDeckName);
          }
        }}
        in:scaleFadeIn|local
        out:scaleFadeOut|local
        class="absolute bottom-0 mb-4 mx-auto bg-c-text text-c-bg rounded-md px-6 py-1 font-bold
          hover:bg-c-primary hover:text-white transition-colors duration-250 ease-out shadow-md
          origin-bottom"
      >
        Change Deck Name
      </button>
    {/if}
    {#if isChangeDeckNameLoading}
      <div
        class="absolute left-0 top-0 w-full h-full bg-c-bg-card rounded-lg flex justify-center
          items-center"
      >
        <div class="w-12 h-12 md:w-16 md:h-16">
          <Spinner />
        </div>
      </div>
    {/if}
  </Modal>
</User>

<style>
  .animation-arrow {
    animation-name: arrow;
    animation-duration: 0.5s;
    animation-direction: alternate;
    animation-iteration-count: 8;
    animation-timing-function: cubic-bezier(0.4, 0, 0.5, 1);
    transform: translateX(0rem);
    transform-origin: bottom;
  }
  @keyframes arrow {
    0% {
      transform: translateX(0rem);
    }
    100% {
      transform: translateX(-1.5rem);
    }
  }
  input {
    background-color: var(--c-bg-30);
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: var(--c-bg-30);
  }
  input:focus,
  input:focus:not(:placeholder-shown) {
    border-color: var(--c-primary);
  }
  input:not(:placeholder-shown) {
    border-color: var(--c-bg-30);
  }
  label {
    font-size: 1.25rem;
    opacity: 0.5;
    color: var(--c-text);
    margin-left: 0.75rem;
    margin-bottom: 0.375rem;
  }
  input:focus + label {
    margin-bottom: 3rem;
    font-size: 1rem;
    font-weight: 500;
    opacity: 1;
    color: var(--c-primary);
  }
  input:not(:placeholder-shown) + label {
    margin-bottom: 3rem;
    font-size: 1rem;
    font-weight: 500;
    opacity: 1;
  }
</style>
