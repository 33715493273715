export let firebaseConfig = {
  apiKey: "AIzaSyCHcvQCdpIUArOwF3iOJ2aKo2ibjq8g990",
  authDomain: "deck-ninja.firebaseapp.com",
  databaseURL: "https://deck-ninja.firebaseio.com",
  projectId: "deck-ninja",
  storageBucket: "deck-ninja.appspot.com",
  messagingSenderId: "556829241258",
  appId: "1:556829241258:web:4e38312f2e82c7630585d3",
  measurementId: "G-3EEG66KD5V",
};
