export function swipe(node, params) {
  let initialX;
  let initialY;
  let threshold = params?.threshold || 40;

  function preventDefault(e) {
    let diffY = e.changedTouches[0].clientY - initialY;
    if (diffY > 0) {
      e.preventDefault();
    }
  }
  function touchStart(e) {
    initialX = e.touches[0].clientX;
    initialY = e.touches[0].clientY;
    if (e.touches.length == 1) {
      node.addEventListener("touchend", touchEnd, { passive: false });
      node.addEventListener("touchmove", touchMove, { passive: false });
    }
  }

  function touchMove(e) {
    preventDefault(e);
    document.body.addEventListener("touchmove", preventDefault, { passive: false });
  }

  function touchEnd(e) {
    let diffX = e.changedTouches[0].clientX - initialX;
    let diffY = e.changedTouches[0].clientY - initialY;
    // If difference is bigger than threshold
    if (Math.abs(diffX) > threshold || Math.abs(diffY) > threshold) {
      if (Math.abs(diffX) > Math.abs(diffY)) {
        // horizontal swipe
        if (diffX > 0) {
          node.dispatchEvent(new CustomEvent("swiperight"));
        } else {
          node.dispatchEvent(new CustomEvent("swipeleft"));
        }
      } else {
        // vertical swipe
        if (diffY > 0) {
          node.dispatchEvent(new CustomEvent("swipedown"));
        } else {
          node.dispatchEvent(new CustomEvent("swipeup"));
        }
      }
    }
    node.removeEventListener("touchend", touchEnd, { passive: false });
    node.removeEventListener("touchmove", touchMove, { passive: false });
    document.body.removeEventListener("touchmove", preventDefault, { passive: false });
  }

  node.addEventListener("touchstart", touchStart, { passive: false });
  return {
    destroy() {
      node.removeEventListener("touchstart", touchStart, { passive: false });
    },
  };
}
