<script>
  import SignedInMenu from "./SignedInMenu.svelte";
  import { User } from "sveltefire";
  import { getContext } from "svelte";
  const app = getContext("firebase").getFirebase();
  const googleProvider = new app.auth.GoogleAuthProvider();
</script>

<User let:user let:auth persist={localStorage}>
  <!------ Signed In ------>
  <SignedInMenu {user} {auth} />
  <!------ Signed Out ------>
  <div slot="signed-out">
    <button
      on:click={auth.signInWithPopup(googleProvider)}
      class="bg-c-text text-sm text-c-bg rounded-md px-4 py-1_5 font-medium hover:bg-c-primary
        hover:text-white transition-colors duration-250 ease-out shadow-button my-1 ml-6"
    >
      Sign in with <span class="font-bold">Google</span>
    </button>
  </div>
</User>
