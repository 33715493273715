<script>
  import Icon from "./../Icon.svelte";
  import ClickOutside from "./../helpers/ClickOutside.svelte";
  import SideButton from "./../buttons/SideButton.svelte";
  import Spinner from "../Spinner.svelte";
  import {
    turnFrontCardIn,
    turnFrontCardOut,
    turnBackCardIn,
    turnBackCardOut,
    scaleFadeOut,
    scaleFadeIn,
  } from "../helpers/transitions.js";

  import { getContext } from "svelte";
  import { fade } from "svelte/transition";
  import { isAddCardOpen } from "./../../plugins/stores.js";

  const app = getContext("firebase").getFirebase();
  let serverTimestamp = new app.firestore.FieldValue.serverTimestamp();
  let user = app.auth().currentUser;

  export let frontText;
  export let backText;
  export let loading;
  export let ref;
  export { classes as class };

  export let isCardOpen = false;

  let flipped = false;
  let frontTextInput;
  let backTextInput;
  let classes;
  let cardCreationLoading = false;

  function flipCard() {
    flipped = !flipped;
  }
  function closeCard() {
    isCardOpen = false;
    $isAddCardOpen = false;
  }
  function openCard() {
    isCardOpen = true;
    $isAddCardOpen = true;
  }
  function handleKeydownFront(event) {
    let keyCode = event.keyCode;
    if (keyCode == "13") {
      if (frontText && backText) {
        createCard();
      } else if (frontText) {
        flipped = true;
        setTimeout(() => {
          backTextInput.focus();
        }, 200);
      } else {
        return null;
      }
    }
  }
  function handleKeydownBack(event) {
    let keyCode = event.keyCode;
    if (keyCode == "13") {
      if (frontText && backText) {
        createCard();
      } else if (backText) {
        flipped = false;
        setTimeout(() => {
          frontTextInput.focus();
        }, 200);
      } else {
        return null;
      }
    }
  }
  async function createCard() {
    try {
      cardCreationLoading = true;
      isCardOpen = false;
      await ref.add({
        uid: user.uid,
        frontText: frontText,
        backText: backText,
        learned: false,
        createdAt: serverTimestamp,
        hidden: false,
      });
      flipped = false;
      frontText = "";
      backText = "";
      cardCreationLoading = false;
    } catch (error) {
      console.log(error);
      cardCreationLoading = false;
    }
  }
</script>

<ClickOutside on:clickoutside={!loading && !cardCreationLoading ? closeCard : null}>
  <div
    class="{isCardOpen ? 'w-90vw h-40vh min-h-48 max-h-84 md:h-84' : 'h-8vh min-h-12 max-h-18 md:h-18'}
      w-90vw max-w-84 md:w-84 relative perspective transition-all duration-250 ease-out {classes}"
  >
    {#if !loading}
      <!-- Card Open -->
      {#if !cardCreationLoading}
        {#if isCardOpen}
          <div
            in:fade|local={{ duration: 150 }}
            out:fade|local={{ duration: 150, delay: 50 }}
            class="w-full h-full"
          >
            {#if !flipped}
              <!-- Front Side -->
              <div
                in:turnFrontCardIn|local
                out:turnFrontCardOut|local
                class="absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center
                  bg-c-bg-card border border-c-border-card rounded-lg"
              >
                <div
                  class="{isCardOpen ? 'opacity-100' : 'opacity-0'} transition-all duration-300 ease-out
                    w-full px-10"
                >
                  <div class="w-full relative">
                    <input
                      bind:value={frontText}
                      bind:this={frontTextInput}
                      on:keydown={handleKeydownFront}
                      disabled={flipped || cardCreationLoading || !isCardOpen}
                      placeholder=" "
                      class="w-full text-xl font-bold px-3 py-1_5 transition-all duration-200
                        ease-out"
                    />
                    <label
                      for="frontTextInput"
                      class="absolute left-0 bottom-0 transition-all duration-200 ease-out
                        pointer-events-none"
                    >
                      Frontside text
                    </label>
                  </div>
                </div>
                <SideButton
                  side="left"
                  onClick={!cardCreationLoading && isCardOpen ? flipCard : null}
                  show={!flipped}
                />
                <SideButton
                  side="right"
                  onClick={!cardCreationLoading && isCardOpen ? flipCard : null}
                  show={!flipped}
                />
                {#if frontText != null && backText != null && frontText?.length > 0 && backText?.length > 0}
                  <button
                    on:click={createCard}
                    in:scaleFadeIn|local
                    out:scaleFadeOut|local
                    class="absolute bottom-0 mb-4 mx-auto bg-c-text text-c-bg rounded-md px-6 py-1
                      font-bold hover:bg-c-primary hover:text-white transition-colors duration-250
                      ease-out shadow-md origin-bottom"
                  >
                    Create Card
                  </button>
                {/if}
              </div>
            {:else}
              <!-- Back Side -->
              <div
                in:turnBackCardIn|local
                out:turnBackCardOut|local
                class="absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center
                  bg-card-back border border-c-border-card rounded-lg"
              >
                <div
                  class="{isCardOpen ? 'opacity-100' : 'opacity-0'} transition-all duration-300 ease-out
                    w-full px-10"
                >
                  <div class="w-full relative">
                    <input
                      bind:value={backText}
                      bind:this={backTextInput}
                      on:keydown={handleKeydownBack}
                      disabled={!flipped || cardCreationLoading || !isCardOpen}
                      placeholder=" "
                      class="w-full text-xl font-bold px-3 py-1_5 transition-all duration-200
                        ease-out"
                    />
                    <label
                      for="backTextInput"
                      class="absolute left-0 bottom-0 transition-all duration-200 ease-out
                        pointer-events-none"
                    >
                      Backside text
                    </label>
                  </div>
                </div>
                <SideButton
                  side="left"
                  onClick={!cardCreationLoading && isCardOpen ? flipCard : null}
                  show={flipped}
                />
                <SideButton
                  side="right"
                  onClick={!cardCreationLoading && isCardOpen ? flipCard : null}
                  show={flipped}
                />
                {#if frontText != null && backText != null && frontText?.length > 0 && backText?.length > 0}
                  <button
                    on:click={createCard}
                    in:scaleFadeIn|local
                    out:scaleFadeOut|local
                    class="absolute bottom-0 mb-4 mx-auto bg-c-text text-c-bg rounded-md px-6 py-1
                      font-bold hover:bg-c-primary hover:text-white transition-colors duration-250
                      ease-out shadow-md origin-bottom"
                  >
                    Create Card
                  </button>
                {/if}
              </div>
            {/if}
          </div>
        {:else}
          <!-- Add button -->
          <button
            in:fade|local={{ duration: 150 }}
            out:fade|local={{ duration: 150, delay: 50 }}
            on:click={!loading && !cardCreationLoading ? openCard : null}
            class="{isCardOpen ? 'opacity-0 pointer-events-none' : 'opacity-100'} absolute left-0 top-0
              w-full h-full flex flex-col justify-center items-center bg-c-bg-card border border-c-border-card
              hover:bg-c-border-card rounded-lg transition-all duration-300 ease-out"
          >
            <div class="w-10 h-10">
              <Icon type="plus" color="text-c-text-30" />
            </div>
          </button>
        {/if}
      {:else}
        <div
          class="absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center
            bg-c-bg-card border border-c-border-card rounded-lg"
        >
          <div class="w-8 h-8 md:w-12 md:h-12">
            <Spinner />
          </div>
        </div>
      {/if}
    {:else}
      <div
        class="absolute left-0 top-0 w-full h-full bg-c-bg-card border border-c-border-card
          rounded-lg flex flex-col items-center justify-center"
      >
        <span
          class="text-xl font-bold text-transparent bg-c-text text-center px-4 rounded-full
            opacity-50 opacity-animation pointer-events-none"
        >
          +
        </span>
      </div>
    {/if}
  </div>
</ClickOutside>

<style>
  .bg-card-back {
    background: repeating-linear-gradient(
      45deg,
      var(--c-bg-card),
      var(--c-bg-card) 1rem,
      var(--c-bg-card-sec) 1rem,
      var(--c-bg-card-sec) 2rem
    );
  }
  .perspective {
    perspective: 100rem;
  }
  input {
    background-color: var(--c-bg-30);
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: var(--c-bg-30);
  }
  input:focus,
  input:focus:not(:placeholder-shown) {
    border-color: var(--c-primary);
  }
  input:not(:placeholder-shown) {
    border-color: var(--c-bg-30);
  }
  label {
    font-size: 1.25rem;
    opacity: 0.5;
    color: var(--c-text);
    margin-left: 0.75rem;
    margin-bottom: 0.375rem;
  }
  input:focus + label {
    margin-bottom: 3rem;
    font-size: 1rem;
    font-weight: 500;
    opacity: 1;
    color: var(--c-primary);
  }
  input:not(:placeholder-shown) + label {
    margin-bottom: 3rem;
    font-size: 1rem;
    font-weight: 500;
    opacity: 1;
  }
</style>
