<script>
  export let type;
  export let color;
  export { classes as class };
  let classes;
</script>

{#if type == 'back'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M10 19l-7-7m0 0l7-7m-7 7h18"
    /></svg>
{:else if type == 'settings'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      fill-rule="evenodd"
      d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
      clip-rule="evenodd"
    /></svg>
{:else if type == 'minus'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      fill-rule="evenodd"
      d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
      clip-rule="evenodd"
    /></svg>
{:else if type == 'x' || type == 'cancel' || type == 'close'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      fill-rule="evenodd"
      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
      clip-rule="evenodd"
    /></svg>
{:else if type == 'arrow-left'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="M7 16l-4-4m0 0l4-4m-4 4h18"
    /></svg>
{:else if type == 'arrow-up'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="M8 7l4-4m0 0l4 4m-4-4v18"
    /></svg>
{:else if type == 'plus'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
    /></svg>
{:else if type == 'trashcan'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      fill-rule="evenodd"
      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
      clip-rule="evenodd"
    /></svg>
{:else if type == 'edit' || type == 'pen'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
    /></svg>
{:else if type == 'logout' || type == 'signout'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      d="M2.5 6.5a3 3 0 013-3h4a3 3 0 013 3V9h-5a3 3 0 100 6h5v2.5a3 3 0 01-3 3h-4a3 3 0 01-3-3v-11z"
      fill="currentColor"
    />
    <path
      d="M17.2 7.3a1 1 0 10-1.4 1.4L18 11H7.5a1 1 0 100 2h10.6l-2.3 2.3a1 1 0 001.4 1.4l4-4c.4-.4.4-1 0-1.4l-4-4z"
      fill="currentColor"
    /></svg>
{:else if type == 'dark'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" /></svg>
{:else if type == 'threedot'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
    /></svg>
{:else if type == 'tick-badge'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      fill-rule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clip-rule="evenodd"
    /></svg>
{:else if type == 'menu-right'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      fill-rule="evenodd"
      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
      clip-rule="evenodd"
    /></svg>
{:else if type == 'filter'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      fill-rule="evenodd"
      d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
      clip-rule="evenodd"
    /></svg>
{:else if type == 'rewind'}
  <svg
    class="{classes} w-full h-full transition-all duration-250 ease-out {color ? color : 'text-c-text'}"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      d="M8.445 14.832A1 1 0 0010 14v-2.798l5.445 3.63A1 1 0 0017 14V6a1 1 0 00-1.555-.832L10 8.798V6a1 1 0 00-1.555-.832l-6 4a1 1 0 000 1.664l6 4z"
    /></svg>
{/if}
