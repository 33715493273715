<script>
  import Icon from "./Icon.svelte";
  import ClickOutside from "./helpers/ClickOutside.svelte";
  import { fade } from "svelte/transition";
  import { scaleFadeIn, scaleFadeOut } from "./helpers/transitions";
  export let isOpen;
  export let closeModal;
</script>

{#if isOpen}
  <div
    transition:fade|local={{ duration: 200 }}
    class="fixed left-0 top-0 h-full w-full bg-c-bg-modal flex flex-col z-50 overflow-hidden pt-16
      md:pt-20 pb-8 md:pb-12"
  >
    {#if isOpen}
      <div class="w-full h-full overflow-y-auto flex flex-col">
        <ClickOutside class="m-auto" on:clickoutside={closeModal}>
          <div
            in:scaleFadeIn
            out:scaleFadeOut
            class="m-auto bg-c-bg-card border border-c-border-card w-90vw h-40vh min-h-48 max-w-84
              max-h-84 md:w-84 md:h-84 rounded-lg flex flex-col justify-center items-center relative
              overflow-scroll no-scroll-bar shadow-modal"
          >
            <slot />
            <!-- Close Button -->
            <button
              on:click={closeModal}
              class="w-10 h-10 p-2 rounded-full hover:bg-c-text-10 transition-colors duration-250
                ease-out absolute top-0 right-0"
            >
              <Icon type="x" color="text-c-text-30" />
            </button>
          </div>
        </ClickOutside>
      </div>
    {/if}
  </div>
{/if}
