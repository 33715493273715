<script>
  export let user;
  export let toggleMenu;

  let shortEmailText = () => {
    let emailText = user.email.split("@")[0];
    let emailLength = emailText.length;
    if (emailLength > 15) {
      emailText = emailText.substring(0, 12) + "...";
    }
    return emailText;
  };
</script>

<button
  on:click={toggleMenu}
  class="flex flex-row items-center hover:bg-c-bg-card focus:bg-c-bg-card border border-transparent
    hover:border-c-border-card focus:border-c-border-card px-2 rounded-lg transition-all
    duration-250 ease-out ml-6"
>
  <div class="w-6 h-6 rounded-full my-2 bg-c-text" />
  <h3 class="flex-1 my-2 font-bold ml-2 text-right">{shortEmailText()}</h3>
</button>
