<script>
  export { classes as class };
  export let classInner;
  export let theme;
  let classes;
</script>

{#if theme == 'theme-light'}
  <svg
    class={classes}
    fill="none"
    height="28"
    viewBox="0 0 148 28"
    width="148"
    xmlns="http://www.w3.org/2000/svg"
  ><g class={classInner} fill="currentColor">
      <path
        d="M12.25 11.81a2.19 2.19 0 11-4.38 0 2.19 2.19 0 014.38 0zM20.13 11.81a2.19 2.19 0 11-4.38 0 2.19 2.19 0 014.38 0z"
      />
      <path
        clip-rule="evenodd"
        d="M0 6a6 6 0 016-6h16a6 6 0 016 6v16a6 6 0 01-6 6H6a6 6 0 01-6-6zm25.5 1.88h-23v7.87h23z"
        fill-rule="evenodd"
      />
      <path
        d="M109.7 6.87c0 1.26.97 2.12 2.2 2.12s2.2-.86 2.2-2.12c0-1.26-.97-2.12-2.2-2.12s-2.2.86-2.2 2.12zM113.45 10.42h-3.1v12.22h3.1z"
      />
      <path
        clip-rule="evenodd"
        d="M38 22.64V5.39h5.91c4.64 0 7.1 2.3 7.1 7v3.25c0 4.7-2.47 7-7.1 7zm5.96-14.3h-2.7v11.34h2.7c2.79 0 3.8-1.43 3.8-3.94v-3.45c0-2.51-1.01-3.94-3.8-3.94zM52.4 16.43c0 3.8 2.63 6.55 6.28 6.55 3.27 0 4.7-1.72 5.54-3l-2.54-1.48a3.2 3.2 0 01-3.05 1.82c-1.78 0-2.93-1.26-3.06-2.88h8.9v-1.2c0-3.68-2.4-6.17-5.94-6.17-3.6 0-6.14 2.66-6.14 6.36zm3.2-1.16c.24-1.5 1.15-2.54 2.9-2.54 1.6 0 2.6.99 2.81 2.54z"
        fill-rule="evenodd"
      />
      <path
        d="M65.65 16.53c0 3.8 2.81 6.45 6.36 6.45 3.42 0 5.57-1.9 6.16-4.68l-3.06-.76c-.32 1.6-1.18 2.68-3.05 2.68-2 0-3.3-1.48-3.3-3.7s1.42-3.69 3.3-3.69c1.87 0 2.83 1.23 3.05 2.71l3.06-.79c-.64-2.78-2.76-4.68-6.16-4.68a6.24 6.24 0 00-6.36 6.46zM92.8 10.42h-4.34l-4.98 4.19h-.3V5.39h-3.1v17.25h3.1v-4.22l1.88-1.55L89 22.64h3.6l-5.35-7.62zM94.28 5.4v17.24h3.25V8.44h.3l3.94 14.2h5.76V5.39h-3.25v14.2h-.3l-3.94-14.2zM119.32 22.64h-3.1V10.42h3.05v1.97h.35c.5-1.14 1.62-2.17 3.87-2.17 2.7 0 4.75 1.95 4.75 5.12v7.3h-3.1v-7c0-1.7-.99-2.71-2.76-2.71-2 0-3.06 1.23-3.06 3.4zM132.5 8.99c-1.23 0-2.2-.86-2.2-2.12 0-1.26.97-2.12 2.2-2.12s2.2.86 2.2 2.12c0 1.26-.97 2.12-2.2 2.12zM130.95 10.42v14.23c0 .5-.25.74-.7.74H128V28h3.64c1.38 0 2.41-.98 2.41-2.36V10.42z"
      />
      <path
        clip-rule="evenodd"
        d="M136.42 13.23l2.51 1.62c.77-1.28 1.78-2.12 3.4-2.12 1.68 0 2.57.91 2.57 2.07v.3l-4.69.52c-2.34.24-4.04 1.6-4.04 3.54 0 2.34 1.83 3.82 4.59 3.82 2.58 0 3.45-1.25 3.84-2.07h.34v1.73H148v-7.81c0-2.86-2.17-4.76-5.5-4.76-2.97 0-4.63 1.33-6.08 3.16zm3 5.76c0-.71.57-1.13 1.73-1.28l3.75-.44v.2c0 1.67-1.21 2.95-3.38 2.95-1.38 0-2.1-.6-2.1-1.43z"
        fill-rule="evenodd"
      />
    </g></svg>
{:else}
  <svg
    class={classes}
    fill="none"
    height="28"
    viewBox="0 0 148 28"
    width="148"
    xmlns="http://www.w3.org/2000/svg"
  ><path
      class={classInner}
      fill="currentColor"
      clip-rule="evenodd"
      d="M6 0a6 6 0 00-6 6v16a6 6 0 006 6h16a6 6 0 006-6V6a6 6 0 00-6-6zm-.25 1.75a4 4 0 00-4 4v16.5a4 4 0 004 4h16.5a4 4 0 004-4V5.75a4 4 0 00-4-4zM24.5 7.88h-21v7.87h21zM12.25 11.8a2.19 2.19 0 11-4.38 0 2.19 2.19 0 014.38 0zm7.88 0a2.19 2.19 0 11-4.38 0 2.19 2.19 0 014.38 0zm89.58-4.94c0 1.26.96 2.12 2.19 2.12s2.2-.86 2.2-2.12c0-1.26-.97-2.12-2.2-2.12s-2.2.86-2.2 2.12zm3.74 3.55h-3.1v12.22h3.1zM38 22.64V5.39h5.91c4.64 0 7.1 2.3 7.1 7v3.25c0 4.7-2.47 7-7.1 7zm5.96-14.3h-2.7v11.34h2.7c2.79 0 3.8-1.43 3.8-3.94v-3.45c0-2.51-1.01-3.94-3.8-3.94zm8.43 8.09c0 3.8 2.64 6.55 6.29 6.55 3.27 0 4.7-1.72 5.54-3l-2.54-1.48a3.2 3.2 0 01-3.05 1.82c-1.78 0-2.93-1.26-3.06-2.88h8.9v-1.2c0-3.68-2.4-6.17-5.94-6.17-3.6 0-6.14 2.66-6.14 6.36zm3.2-1.16c.25-1.5 1.16-2.54 2.91-2.54 1.6 0 2.6.99 2.81 2.54zm10.06 1.26c0 3.8 2.81 6.45 6.36 6.45 3.42 0 5.57-1.9 6.16-4.68l-3.06-.76c-.32 1.6-1.18 2.68-3.05 2.68-2 0-3.3-1.48-3.3-3.7s1.42-3.69 3.3-3.69c1.87 0 2.83 1.23 3.05 2.71l3.06-.79c-.64-2.78-2.76-4.68-6.16-4.68a6.24 6.24 0 00-6.36 6.46zm27.14-6.11h-4.33l-4.98 4.19h-.3V5.39h-3.1v17.25h3.1v-4.22l1.88-1.55L89 22.64h3.6l-5.35-7.62zm1.49-5.03v17.25h3.25V8.44h.3l3.94 14.2h5.76V5.39h-3.25v14.2h-.3l-3.94-14.2zm25.04 17.25h-3.1V10.42h3.05v1.97h.35c.5-1.14 1.62-2.17 3.87-2.17 2.7 0 4.75 1.95 4.75 5.12v7.3h-3.1v-7c0-1.7-.99-2.71-2.76-2.71-2 0-3.06 1.23-3.06 3.4zM132.5 8.99c-1.23 0-2.2-.86-2.2-2.12 0-1.26.97-2.12 2.2-2.12s2.2.86 2.2 2.12c0 1.26-.97 2.12-2.2 2.12zm-1.55 1.43v14.23c0 .5-.25.74-.7.74H128V28h3.64c1.38 0 2.41-.98 2.41-2.36V10.42zm5.47 2.8l2.51 1.63c.77-1.28 1.78-2.12 3.4-2.12 1.68 0 2.57.91 2.57 2.07v.3l-4.69.52c-2.34.24-4.04 1.6-4.04 3.54 0 2.34 1.83 3.82 4.59 3.82 2.58 0 3.45-1.25 3.84-2.07h.34v1.73H148v-7.81c0-2.86-2.17-4.76-5.5-4.76-2.97 0-4.63 1.33-6.08 3.16zm3 5.77c0-.71.57-1.13 1.73-1.28l3.75-.44v.2c0 1.67-1.21 2.95-3.38 2.95-1.38 0-2.1-.6-2.1-1.43z"
      fill-rule="evenodd"
    /></svg>
{/if}
