import { cubicOut, cubicIn } from "svelte/easing";

export const turnFrontCardIn = (node, { delay = 0, duration = 500, isActive = true }) => {
  return {
    delay,
    duration,
    css: (t) => {
      if (isActive) {
        const easedOpacity = t < 0.2 ? 0 : 1;
        const eased = cubicOut(t);
        return `opacity: ${easedOpacity}; transform: rotate3d(0, -1, 0, ${180 - eased * 180}deg);`;
      }
    },
  };
};

export const turnFrontCardOut = (node, { delay = 0, duration = 500, isActive = true }) => {
  return {
    delay,
    duration,
    css: (t) => {
      if (isActive) {
        const easedOpacity = t > 0.8 ? 1 : 0;
        const eased = cubicIn(t);
        return `opacity: ${easedOpacity}; transform: rotate3d(0, 1, 0, ${180 - eased * 180}deg);`;
      }
    },
  };
};

export const turnBackCardOut = (node, { delay = 0, duration = 500, isActive = true }) => {
  return {
    delay,
    duration,
    css: (t) => {
      if (isActive) {
        const easedOpacity = t > 0.8 ? 1 : 0;
        const eased = cubicIn(t);
        return `opacity: ${easedOpacity}; transform: rotate3d(0, 1, 0, ${180 - eased * 180}deg);`;
      }
    },
  };
};

export const turnBackCardIn = (node, { delay = 0, duration = 500, isActive = true }) => {
  return {
    delay,
    duration,
    css: (t) => {
      if (isActive) {
        const easedOpacity = t < 0.2 ? 0 : 1;
        const eased = cubicOut(t);
        return `opacity: ${easedOpacity}; transform: rotate3d(0, -1, 0, ${180 - eased * 180}deg);`;
      }
    },
  };
};

export const scaleFadeIn = (node, { delay = 0, duration = 300 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      const eased = cubicOut(t);
      return `transform: scale(${0.25 + (3 * eased) / 4}); opacity:${0.25 + (3 * eased) / 4}`;
    },
  };
};

export const scaleFadeOut = (node, { delay = 0, duration = 300 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      const eased = cubicIn(t);
      return `transform: scale(${eased}); opacity:${eased}`;
    },
  };
};

export const cardIn = (node, { delay = 0, duration = 300 }) => {
  let deckBack = node.classList.contains("deck-back");
  let deckMiddle = node.classList.contains("deck-middle");
  if (deckBack && duration > 0) {
    duration = duration + 200;
  } else if (deckMiddle && duration > 0) {
    duration = duration + 100;
  }
  return {
    delay,
    duration,
    css: (t) => {
      //const style = getComputedStyle(node);
      const eased = cubicOut(t);
      if (deckBack) {
        return `margin-top: ${(eased - 1) * 40}%;`;
      } else if (deckMiddle) {
        return `margin-top: ${(eased - 1) * 35}%;`;
      } else {
        return `margin-top: ${(eased - 1) * 30}%;`;
      }
    },
  };
};

export const cardOut = (node, { delay = 0, duration = 400 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      //const style = getComputedStyle(node);
      const eased = cubicIn(t);
      return `transform: translateY(${(1 - eased) * 30}%) scale(${
        8 / 7 - (1 / 7) * eased
      }); opacity:${t}`;
    },
  };
};

export const fadeTextIn = (node, { delay = 0, duration = 250 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      const eased = cubicOut(t);
      return `transform: translateY(${(1 - eased) / 2}rem); opacity:${eased}`;
    },
  };
};

export const fadeTextOut = (node, { delay = 0, duration = 250 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      const eased = cubicIn(t);
      return `transform: translateY(${(1 - eased) / 2}rem); opacity:${eased}`;
    },
  };
};

export const deckListIn = (node, { delay = 0, duration = 300 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      const eased = t;
      return `transform: translateX(${-75 * (1 - eased)}%); opacity:${eased}`;
    },
  };
};

export const deckListOut = (node, { delay = 0, duration = 300 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      const eased = t;
      return `position:absolute; transform: translateX(${-75 * (1 - eased)}%); opacity:${eased}`;
    },
  };
};

export const deckIn = (node, { delay = 0, duration = 300 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      const eased = t;
      return `transform: translateX(${75 * (1 - eased)}%); opacity:${eased}`;
    },
  };
};

export const deckOut = (node, { delay = 0, duration = 300 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      const eased = t;
      return `position:absolute; transform: translateX(${75 * (1 - eased)}%); opacity:${eased}`;
    },
  };
};

export const translateFadeIn = (node, { delay = 0, duration = 300 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      //const style = getComputedStyle(node);
      const eased = cubicOut(t);
      return `transform: translateY(${(1 - eased) * 30}%); opacity:${t}`;
    },
  };
};

export const translateFadeOut = (node, { delay = 0, duration = 300 }) => {
  return {
    delay,
    duration,
    css: (t) => {
      //const style = getComputedStyle(node);
      const eased = cubicIn(t);
      return `transform: translateY(${(1 - eased) * 30}%); opacity:${t}`;
    },
  };
};
