<script>
  import Icon from "./../Icon.svelte";

  export let learned = false;
  export { classes as class };

  let classes;
</script>

{#if learned == true}
  <div class={classes}>
    <Icon type="tick-badge" color="text-c-success" />
  </div>
{/if}
